.element {
  width: 67px;
  height: 67px;
  background-image: url('../../commons/assets/images/loading.png');
  animation: spin 1.3s linear infinite;
  background-repeat: no-repeat;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}