:root {
  --primary-font-family: Roboto, sans-serif;
}

* {
  outline: none !important;
}
button:active, button:focus {
  box-shadow: none !important;
  outline: none !important;
}
html {
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
body {
  flex: 1;
  display: flex;
  font-size: 14px;
  color: #333333;
  line-height: 1.29;
  overflow: hidden;
  position: fixed;
}
body, p {
  font-family: var(--primary-font-family);
}
a, a:hover, a:focus {
  color: #333333;
  text-decoration: underline;
}
strong {
  font-weight: 600;
}
.natura-sans {
  font-family: var(--primary-font-family), sans-serif;
}
.bold {
  font-weight: 600 !important;
}
.light {
  font-weight: 300 !important;
}
.regular {
  font-weight: 400 !important;
}
.medium {
  font-weight: 500 !important;
}
.btn-link, .btn-link:hover, .btn-link:focus {
  border: none;
  font-family: var(--primary-font-family);
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f6be0f;
  text-decoration: underline;
  cursor: pointer;
}
.btn-link:hover {
  text-decoration: underline;
}
#root {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}
.container {
  max-width: 1280px;
}
.container-fluid.h-100 {
  height: calc(100vh - 70px) !important;
  margin-top: 68px;
}
.alert {
  border-radius: 0;
}
.card {
  position: relative;
  border-radius: 0;
  border: none;
}
.form-group .input-icon {
  position: relative;
}
.dark-gray {
  color: #333333;
}
.medium-gray {
  color: #777777;
}
.background-orange {
  background-color: #F6BE0F;
}
.background-white {
  background-color: #ffffff;
}
.btn-form-submit {
  border-radius: 6px;
  width: 100%;
  font-size: 1em;
  background-color: #F6BE0F;
  color: #fff;
  text-transform: uppercase;
  font-family: var(--primary-font-family);
  outline: none !important;
  box-shadow: none !important;
}
.btn-form-submit.large {
  width: 104px;
  height: 48px;
  white-space: normal;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
}
.btn-white {
  border-radius: 6px;
  width: 100%;
  font-size: 1em;
  background-color: #ffffff;
  color: #777777;
  text-transform: uppercase;
  font-family: var(--primary-font-family);
  border: 1px solid #777777;
}
.btn-gray {
  border-radius: 6px;
  width: 100%;
  font-size: 1em;
  background-color: #eeeeee;
  color: #9b9b9b;
  text-transform: uppercase;
  font-family: var(--primary-font-family);
  border: 1px solid #eeeeee;
}
.btn-border-black {
  border-radius: 6px;
  background-color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #777777;
  width: 104px;
  height: 48px;
  font-family: var(--primary-font-family);
  font-size: 10px;
  font-weight: 500;
  white-space: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #333333;
}
.btn-border-orange {
  border-radius: 6px;
  background-color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #f6be0f;
  width: 104px;
  height: 48px;
  font-family: var(--primary-font-family);
  font-size: 10px;
  font-weight: 500;
  white-space: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #f6be0f;
}
.label-position {
  border-radius: 15px;
  box-shadow: 2px 2px 20px #eee;
  color: #bbbbbb;
  font-size: 13px;
  font-weight: 600;
}
.label-position .content {
  align-items: center;
  vertical-align: middle;
  align-self: center;
  text-align: center;
  display: inline-flex;
}
.label-position .content .big {
  font-size: 30px;
  margin-right: 10px;
  font-family: var(--primary-font-family);
}
.chart {
  position: relative;
}
.btn.btn-toogle-chart {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 20px;
  height: 20px;
  padding: 0;
}
.btn.btn-toogle-chart img {
  width: 100%;
  transition: transform 0.3s;
}
.btn.btn-toogle-chart.open img {
  transform: rotate(-180deg);
}
.collapse {
  transition: all 0.3s linear;
  max-height: 0px;
  display: block !important;
  overflow: hidden;
}
.collapse.show {
  max-height: 220px;
}
.search-container.show {
  position: fixed;
  top: 0;
  z-index: 1202;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: transparent;
  -webkit-transition: all 0.5s;
  /* Safari */
  transition: all 0.5s;
}
.input-caret-orange {
  caret-color: rgb(242, 170, 51);
}
.search .input-group {
  position: relative;
  z-index: 1;
  border-radius: 0.4rem;
  background-color: rgb(255, 255, 255);
  border: solid 1px rgb(187, 187, 187);
  padding: 0.4em;
}
.search-container.show .search .input-group {
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.search-container.show .search .input-group {
  padding: 12px 0.4em;
  height: 56px;
}
.search .btn-search, .search .btn-close, .search .btn-back {
  background-color: transparent;
  border: none;
  position: relative;
  padding: 0;
  cursor: pointer;
  height: auto;
  width: 25px;
  background-position: 5px center;
  background-size: 20px;
  background-repeat: no-repeat;
  margin-right: 14px;
  margin-left: 12px;
}
.search .btn-search {
  background-image: url("../images/ic-search.png");
  background-size: 16px;
}
.search .btn-back {
  background-image: url("../images/ic-back.png");
  display: none;
}
.search .btn-back-promotion {
  background-image: url("../images/ic-back.png");
  display: block;
}
.search .btn-close {
  display: none;
  right: 0;
  width: 25px;
  background-size: 12px;
  background-image: url(../images/ic-close.png);
}
.search-container.show .btn-back {
  display: block;
}
.search-container.show .btn-search {
  display: none;
}
.search-container.show .btn-close {
  display: block;
}
.search input.form-control {
  outline: none !important;
  border: none;
  box-shadow: none;
}
.search input.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bbbbbb;
}
.search input.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #bbbbbb;
}
.search input.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #bbbbbb;
}
.search input.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #bbbbbb;
}
.search .btn-search img {
  width: 20px;
}
.search-container .search-content {
  position: absolute;
  width: 100%;
  background-color: white;
  margin-top: -5px;
  margin-bottom: 20px;
  display: block;
  transition: max-height 0.3s;
  overflow: hidden;
  height: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.search-list ul {
  padding: 0;
  margin: 0;
}
.search-list>ul>li {
  padding: 1.1rem 0.9rem 0.6rem 0.77rem;
}
.search-list>ul>li.list-title {
  padding-bottom: 0;
  padding-left: 1.1rem;
  font-size: 12px;
  font-weight: 500;
  color: #7080a0;
  text-transform: uppercase;
}
.search-list>ul>li.list-item {
  display: flex;
  border-bottom: 1px solid #bbbbbb;
}
.search-list>ul>li.list-item:last-child {
  padding-bottom: 1.5rem;
  border: none;
}
.search-list>ul>li.list-item>button {
  font-family: var(--primary-font-family);
  font-size: 12px;
  color: #333333;
  text-decoration: none;
  display: flex;
  align-self: center;
  align-items: center;
  border: none;
  cursor: pointer;
}
.search-list>ul>li.list-item>button>i {
  width: 23px;
  height: auto;
  display: inline-block;
  margin-right: 0.8rem;
}
.search-list>ul>li.list-item>button>i>img {
  display: block;
  width: 100%;
  height: auto;
}
.card-top-order {
  padding-top: 0;
}
.search-icon {
  background-image: url(../images/ic-search.png);
  background-size: 20px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  background-position: center center;
}
@media (min-width: 992px) {
  .search-list>ul>li.list-item:last-child {
    padding-bottom: none;
  }
  .search .btn-search {
    background-size: 32px;
  }
  .search .btn-search, .search .btn-close, .search .btn-back {
    width: 35px;
  }
  .search .input-group {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  }
  .card {
    border-radius: 0.25rem;
    box-shadow: 2px 3px 10px #c3c3c3;
  }
  .search-container {
    position: relative;
  }
  .search-container.show {
    position: relative;
    width: 100%;
    background-color: transparent !important;
    height: 100%;
  }
  .search-container .search .input-group {
    height: 48px;
    padding: 0.4em;
  }
  .search-container.show .search .input-group {
    border-radius: 0.4rem;
    border-color: #333333;
    height: 48px;
    padding: 0.4em;
  }
  .search-container.show .btn-search {
    display: block;
  }
  .search-container.show .btn-back {
    display: none;
  }
  .search-container.show .btn-close {
    display: none;
  }
  .search input.form-control {
    outline: none !important;
    border: none;
    box-shadow: none;
    border-radius: 1rem;
    font-size: 14px;
  }
  .search-content {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    margin-top: -5px;
    border: 1px solid #bbbbbb;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    margin-bottom: 20px;
    display: block;
    max-height: 0px;
    -webkit-transition: max-height 0.3s;
    /* Safari */
    transition: max-height 0.3s;
    overflow: hidden;
  }
  .search-list>ul>li {
    padding: 1.2rem 0.9rem 0.6rem 0.77rem;
  }
  .search-list>ul>li.list-item>button>i {
    width: 16px;
  }
}
.popover-default {
  background-color: #ed743e;
  box-shadow: -1px -2px 4px 1px rgba(0, 0, 0, 0.18);
  max-width: unset;
}
.img-close-default {
  padding-right: 0 !important;
  padding-left: 7px !important;
}
.popover-default.popover-body {
  font-family: var(--primary-font-family);
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #ffffff;
}
.popover-default .arrow::after, .popover-default .arrow::before {
  border-top-color: #ed743e;
}
.color-level-bronze {
  background-color: #c47f5b;
}
.color-level-silver {
  background-color: #9d9d9c;
}
.color-level-gold {
  background-color: #d8b75e;
}
.color-level-platinum {
  background-color: #eeeeee;
}
.color-level-sapphire {
  background-color: #0f4d82;
}
.color-level-diamond {
  background-color: #aec2ce;
}
.container-fluid.h-100.container-mobile {
  z-index: 999999999;
  margin-top: 0px;
  height: 100vh !important;
}
@supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
  .container-fluid.h-100.container-mobile {
    z-index: 999999999;
    margin-top: 0px;
    transform: translate3d(0, 0, 0);
    height: 100vh !important;
  }
}
.general-box-container {
  display: flex;
  flex-direction: column;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #0000002E;
  border: 0.5px solid #EEEEEE;
  border-radius: 6px;
  margin: auto;
  width: calc(100% - 2rem);
  padding: .5rem;
}
.general-box-container > * {
  padding: .5rem .5rem ;
}
.general-box-container__wrapper__header {
  display: flex;
  flex-direction: row;
}
.payment-pending__title {
  text-align: left;
  font: normal normal bold 14px/16px var(--primary-font-family);
  justify-content: center;
  margin: auto 0;
  letter-spacing: 0px;
  color: #E74627;
  opacity: 1;
  font-weight: bold;
}
.btn-payment-pending-redirect {
  /* border: 1px solid #f6be0f; */
  border-radius: 6px;
  background-color: #f6be0f;

  text-align: center;
  font: normal normal medium 14px/16px Roboto;
  letter-spacing: 0px;
  color: white;
  text-transform: uppercase;
}

.btn-change-payment-method {
  border: 1px solid #f6be0f;
  border-radius: 6px;
  background-color: transparent;
  text-align: center;
  font: normal normal medium 14px/16px var(--primary-font-family);
  letter-spacing: 0px;
  color: #f6be0f;
  text-transform: uppercase;
}