.card.bg-gradient {
  background-color: #F6BE0F;
  border: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.order-search .card.bg-gradient {
  box-shadow: none;
  z-index: 2;
}

.order-content .card.content-box {
  padding-bottom: 100px !important;
}

.order-content .card.content-box>.loader-container {
  display: flex;
  height: 50vh;
  margin: auto;
  justify-content: center;
  align-self: center;
}

.order-content .card.content-box .element-load {
  background-size: 32px;
  height: 32px;
  width: 32px;
}

.order-content .search-content {
  background-color: #ffffff;
}

.order-content .search-container.show .search-content {
  overflow-x: hidden;
}

.order-content .card.bg-gradient {
  height: 64px;
  border-radius: 6px;
}

.header-slider {
  padding: 22px 0 6.5px;
  position: relative;
}

.header-slider img {
  position: absolute;
  width: 45px;
  top: 18px;
  left: 0;
}

.header-slider h2, .header-slider h3 {
  text-align: left;
  margin: 0 0 0px 56px;
}

.header-slider h2 {
  font-family: var(--primary-font-family);
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: rgb(51, 51, 51);
}

.header-slider h3 {
  font-family: var(--primary-font-family);
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: rgb(119, 119, 119);
}

.slick-slider {
  position: relative;
}

.order-content .slick-slider {
  margin: 0 35px;
  padding: 0 17px;
}

.slick-slide {
  text-align: center;
  width: 290px;
}

.slick-next:before, .slick-prev:before {
  display: none;
}

.slick-next, .slick-prev {
  width: 15px;
  height: 28px;
  z-index: 1;
}

.slick-next img, .slick-prev img {
  width: 100%;
}

.banner-home .slick-prev {
  left: 0;
}

.banner-home .slick-next {
  right: 0;
}

.product-similar-container {
  display: grid;
  background-color: #FFFFFF;
}

.product-similar-label {
  text-align: left;
  margin-left: 15px;
  font-family: var(--primary-font-family);
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #333333;
  margin-top: 20px;
}

.product-similar-line {
  height: 0;
  border: none;
}

.card.mb-3.border-light.px-2.py-2.bg-gradient.mobile-open {
  background-color: unset;
  background-image: unset;
}

.hide-search {
  visibility: hidden;
}

.header-slider .flag {
  object-fit: contain;
  float: left;
  position: relative;
  left: -8px;
  top: 6px;
}

.profitability-card-search {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 4px 0.2px rgba(0, 0, 0, 0.15);
  padding: 8px;
  width: 100%;
  background-color: #ffffff;
  top: -8px;
  border-radius: 6px;
  padding-top: 16px;
  position: relative;
}

.content-similar {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.content-similar .card {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 0 !important;
}

.content-similar-labels {
  height: 100%;
}

.header-similar {
  padding: 10px;
  width: 100%;
  height: 80px;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  background-color: #F6BE0F;
}

.title-header-similiar span {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
}

.sub-title-header-similiar span {
  font-family: var(--primary-font-family);
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}

.separator {
  height: 100%;
  border-left: solid 1px #000000;
}

.container-image-header {
  height: 39px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: invert();
}

@media (min-width: 992px) {
  .header-similar {
    padding: 10px;
    height: 48px;
  }
  .container-image-header {
    height: 32px;
  }
  .header-slider .flag {
    left: -24px;
    top: 6px;
  }
  .product-similar-line {
    border: solid 0.5px #bbbbbb;
  }
  .product-similar-line {
    margin-left: 30px;
    margin-right: 20px;
  }
  .product-similar-label {
    margin-left: 40px;
  }
  .order-content .card.bg-gradient {
    height: 72px;
  }
  .bag .qtt {
    float: inherit;
  }
  .order-content .search-container.show .search-content {
    max-height: 300px !important;
  }
  .scroll-page .order-content .card.content-box {
    padding-bottom: 60px !important;
  }
  .scroll-page .bag {
    bottom: 0;
  }
  .header-slider {
    padding: 24px 14px 5px;
    position: relative;
  }
  .header-slider h2, .header-slider h3 {
    margin: 0 0 0 56px;
  }
  .header-slider .flag {
    object-fit: contain;
    float: left;
    position: relative;
    left: -24px;
    top: 6px;
  }
  .header-slider h2 {
    font-family: var(--primary-font-family);
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    margin-bottom: 4.5px;
    letter-spacing: normal;
    color: #333333;
  }
  .header-slider h3 {
    font-family: var(--primary-font-family);
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #777777;
  }
  .card.content-slider {
    overflow: hidden;
  }
  main .order-search .card.content-box {
    min-height: calc(100vh - 220px)
  }
}

.clear-sorter {
  text-transform: uppercase;
  cursor: pointer;
  color: #7080a0;
}

.order-content .card.content-box>.loader-container.paged {
  height: 0;
  margin-top: 3rem;
}

@media(min-width: 960px) {
  .banner-home .slick-slider {
    margin: 0 35px 20px;
    padding: 20px 17px;
  }

  .banner-home .slick-prev {
    left: -11px;
  }

  .banner-home .slick-next {
    right: -20px;
  }
}

@media (max-width: 1280px) {
  .product-similar-line {
    margin-right: 5px;
  }
}
