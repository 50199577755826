@import '../../../../node_modules/slick-carousel/slick/slick.css';
@import '../../../../node_modules/slick-carousel/slick/slick-theme.css';
.card.content-box {
  min-height: 100px;
}

.card.level-box.hide {
  display: none;
}
.order-header-title {
  display: flex;
}
.order-header-title .title-group {
  flex-grow: 1;
}
.title-home-order {
  font-size: 20px;
  color: #7080a0;
}

.title-home-order {
  margin-top: 1.1rem !important;
}

.card-box {
  padding: 0 0.7rem;
}

.card.content-box.mobile-box {
  padding: 0 0 40px 0 !important;
  background-color: #ffffff;
  width: 95%;
  margin: 17px auto;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.search-container input::placeholder {
  font-size: 14px;
}

.search-container input {
  padding-left: 0;
  padding-right: 1px;
}

.slick-dots li {
  margin: 0;
  width: 10px;
}

.slick-dots li button:before {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #faaa00;
  opacity: 0.4;
  content: '';
}

.slick-dots li button {
  padding: 0px;
  width: auto;
  height: auto;
}

.slick-dots li.slick-active button:before {
  content: '';
  opacity: 1;
  background-color: #faaa00;
}

.slick-dots {
  position: relative;
  bottom: 0;
}

.card.level-box {
  min-height: 0;
}

.order-home-banner {
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  .search-container input {
    padding-right: 12px;
  }
  .card-box {
    padding: 1.6rem 2.2rem 0 2.2rem;
  }
  .bag .qtt {
    float: inherit;
  }
  .title-home-order {
    font-size: 1.5rem;
    margin-top: 0 !important;
  }
  .slick-slider.banner-home.slick-initialized {
    max-width: 100%;
    max-height: 373px;
    overflow: hidden;
    margin: 0 0 24px 0;
    padding: 0;
  }
  .slick-slider.banner-home .slick-slide .has-click {
    cursor: pointer;
  }
  .slick-next.slick-next-home,
  .slick-prev.slick-home {
    width: 24px;
    height: 24px;
  }
  .slick-prev.slick-home {
    left: 20px;
  }
  .slick-next.slick-next-home {
    right: 20px;
  }
  .card.level-box {
    height: auto;
    min-height: 80px;
  }
  
}

@media (min-width: 1300px) {
  .slick-slider.banner-home.slick-initialized {
    max-width: 100%;
    max-height: 548px;
  }
}
