.card.content-box {
  min-height: 100px;
}

.order-content .card.content-box.h-100 {
  height: calc(100% - 180px) !important;
}

.reponsive-container-consultant {
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0px;
  background-color: #fff;
}

.card.level-box {
  height: auto;
}

.card.content-box.consultant-content-box {
  height: calc(100vh - 70px) !important;
  padding-bottom: 0 !important;
}

.card.content-box.select-consultant-container {
  margin-top: 32px;
}

@media (min-width: 992px) {
  .card.level-box {
    padding: 24px 32px;
  }
  .bag .qtt {
    float: inherit;
  }
  .card.content-box.consultant-content-box {
    height: calc(100vh - 70px) !important;
    padding-bottom: 0 !important;
  }
}

.title-consultant {
  font-family: var(--primary-font-family);
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.83;
  letter-spacing: normal;
  color: #7080a0;
  margin-top: 1.375rem;
}

.subtitle-consultant {
  margin-top: 0.4375rem;
  margin-bottom: 1.438rem;
  font-family: var(--primary-font-family);
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #777777;
}

.search-container-label-modal {
  display: contents;
}

.circle-attention {
  max-width: 39px;
  min-width: 39px;
  max-height: 39px;
  min-height: 39px;
  background-color: #e03100;
  border-radius: 50%;
  margin-left: 1rem;
}

.circle-attention img {
  width: 100%;
  height: 100%;
  padding: 28.5%;
}

.attention-label {
  margin-left: 1.063rem;
  font-family: var(--primary-font-family);
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #e03100;
}

.attention-label .title {
  font-family: var(--primary-font-family);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
}

.search-list-contacts.text-left.label-list {
  margin-top: 0.625rem;
  margin-bottom: 0.6875rem;
}

@media (min-width: 992px) {
  .title-consultant {
    margin-top: 0.3125rem;
  }
  .subtitle-consultant {
    margin-top: 0.6875rem;
    margin-bottom: 1.625rem;
  }
  .search-list-contacts.text-left.label-list {
    margin-top: 1rem;
    margin-bottom: 0.1875rem;
  }
  .circle-attention {
    margin-left: 1.563rem;
  }
  .attention-label {
    margin-left: 1.125rem;
    font-size: 14px;
    line-height: 1.29;
    color: #e03100;
  }
  .attention-label .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
}
